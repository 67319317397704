<template>
  <div>
    <div ref="pagebuilder"><b-flix-loader></b-flix-loader></div>
  </div>
</template>
<script>
import loadPageBuilder from '@/components/pageBuilder/load.js'
import { standardLayout } from '@/components/pageBuilder/standardLayout.js'
export default {
  components: {},
  props: {
    onClose: Function
  },
  data() {
    return {
      data: false,
      services: false,
      layout: this.getStandardLayout(),
      variables: this.$getUserVariables(),
      loaded: false
    }
  },
  methods: {
    getStandardLayout() {
      this.variables = this.$getUserVariables()
      return JSON.stringify(standardLayout)
        .split(':id')
        .join(this.$route.params.id)
        .split(':user')
        .join(this.variables.user.md5_id)
    },
    getLayout(callback) {
      var send = {
        user: this.variables.user.md5_id,
        ID: this.$route.params.id
      }
      this.$flix_post({
        url: 'pagebuilder/bookingpage/get',
        data: send,
        callback: function (ret) {
          if (
            typeof ret.data[1] === 'undefined' ||
            typeof ret.data[1].content === 'undefined'
          ) {
            callback()
            return false
          }
          this.layout = ret.data[1].content
          callback()
        }.bind(this)
      })
    },
    setSave(data) {
      this.layout = JSON.stringify(data)

      var send = {
        user: this.variables.user.md5_id,
        ID: this.$route.params.id,
        data: data
      }

      this.$flix_post({
        url: 'pagebuilder/bookingpage/save',
        data: send,
        callback: function (ret) {
          this.onClose()
        }.bind(this)
      })
    },
    loadPageBuilder() {
      loadPageBuilder({
        container: this.$refs.pagebuilder,
        language: this.$i18n.locale,
        blocks: [
          14, 1, 6, 3, 5, 34, 4, 32, 16, 2, 30, 29, 26, 27, 25, 9, 10, 12, 11
        ],
        onClose: function () {
          this.onClose()
        }.bind(this),
        onSave: function (blocks) {
          this.setSave(blocks)
        }.bind(this),
        onChange: function (blocks) {
        },
        layout: JSON.parse(this.layout)
      }).run()
    }
  },
  mounted() {
    this.getLayout(
      function () {
        this.loadPageBuilder()
      }.bind(this)
    )
  },
  computed: {}
}
</script>
<style lang="sass" scoped></style>
